import { LoaderService } from './../../../services/loader.service';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loader',
  standalone: true,
  imports: [],
  templateUrl: './loader.component.html',
  styleUrl: './loader.component.scss'
})
export class LoaderComponent {

  show: boolean = false;

  constructor(public loaderService: LoaderService) {

    this.loaderService.show.subscribe((value: boolean) => {
      this.show = value;
    });
   }

}
