import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-default',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './default.component.html',
  styleUrl: './default.component.scss'
})
export class DefaultComponent implements OnInit {
  invoiceUrl: string = '';
  userName: string = '';

  constructor(
    private router: Router,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.authService.userData$.subscribe(user => {
      if (user) {
        this.userName = user.first_name;
        if (user.default_requestor?.is_defaulting && user.default_requestor?.url) {
          this.invoiceUrl = user.default_requestor.url;
        } else {
          // Se não estiver inadimplente, redireciona
          this.router.navigate(['/contratante']);
        }
      }else{
        this.authService.getUser();
      }
    });
  }


  goToPayments() {
    if (this.invoiceUrl) {
      window.open(this.invoiceUrl, '_blank');
    }
  }

  contactSupport() {
    window.open(`https://api.whatsapp.com/send?phone=553130668360&text=Olá, gostaria de falar com o suporte sobre a plataforma Freelaw.`, '_blank');
  }

  goToLogin() {
    this.router.navigate(['/login']);
  }
}
