import { HttpErrorResponse, HttpHandlerFn, HttpRequest } from "@angular/common/http";
import { catchError, finalize, throwError } from "rxjs";
import { LoaderService } from "../services/loader.service";
import { inject } from "@angular/core";
import { environment } from "../../environments/environment";

let count: number = 0;

export function loaderInterceptor(req: HttpRequest<any>, next: HttpHandlerFn) {

  let loaderService = inject(LoaderService);

  //if is not the api url, return next
  if(!req.url.includes(environment.baseUrl) && !req.url.includes('freelaw-storage')) {
    return next(req);
  }

  if(req.headers.get('no-loader')) {
    return next(req);
  }
  count++;
  loaderService.show.next(true);

  return next(req).pipe(
    finalize(() => {
      count--;
      if(count <= 0) {
        count = 0;
        loaderService.show.next(false);
      }
    }),
    catchError((error: HttpErrorResponse) => {
      count = 0;
      loaderService.show.next(false);
      return throwError(error);
    })
  );

}
