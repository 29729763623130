import { HttpEvent, HttpHandlerFn, HttpRequest, HttpResponse } from "@angular/common/http";
import { Observable, of, tap } from "rxjs";

interface CacheEntry {
  response: HttpResponse<any>;
  timestamp: number;
}

const cache = new Map<string, CacheEntry>();
const DEFAULT_CACHE_DURATION = 10 * 60 * 1000; // 10 minutes in milliseconds

export function cacheInterceptor(req: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>> {
  // Only cache GET requests
  if (req.method !== 'GET') {
    return next(req);
  }

  // Skip cache for specific requests
  if (req.headers.get('no-cache') || true) {
    return next(req);
  }

  // const cacheDuration = Number(req.headers.get('cache-duration')) || DEFAULT_CACHE_DURATION;
  // const cachedEntry = cache.get(req.urlWithParams);

  // if (cachedEntry && !isExpired(cachedEntry, cacheDuration)) {
    // return of(cachedEntry.response.clone());
  // }

  // return next(req).pipe(
    // tap(event => {
      // if (event instanceof HttpResponse) {
        // cache.set(req.urlWithParams, {
          // response: event.clone(),
          // timestamp: Date.now()
        // });
      // }
    // })
  // );
}

function isExpired(entry: CacheEntry, duration: number): boolean {
  return Date.now() - entry.timestamp > duration;
}
