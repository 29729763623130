import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { AuthService } from "../services/auth.service";
import { take } from "rxjs";
import { MatDialog } from '@angular/material/dialog';
import { VerificarTelefoneComponent } from "../components/pages/minha-conta/seguranca/verificar-telefone/verificar-telefone.component";
import { OnboardingComponent } from "../components/pages/onboarding/onboarding.component";

export const canActivateAuth: CanActivateFn = (route, state) => {
  const token = localStorage.getItem('bm_t');
  if (!token) {
    window.location.href = '/login';
    return false;
  }
  return true;
}

export const canActivateRequestor: CanActivateFn = (route, state) => {

  const router = inject(Router);

  inject(AuthService).userData$.subscribe(user => {
    if (user && !user.is_requestor) {
      router.navigate(['/redirect']);
      return false;
    }
      // Verifica se é contratante e está inadimplente

    if (user?.is_requestor && user?.default_requestor?.is_defaulting) {
      router.navigate(['/default']);
      return false;
    }

    return true;
  })

  return true;

}

export const redirectIfAuthenticated: CanActivateFn = (route, state) => {
  const token = localStorage.getItem('bm_t');
  if (!token) {
    window.location.href = '/login';
    return false;
  }

  const router = inject(Router);


  inject(AuthService).userData$.subscribe(user => {
    if (user) {
      if(location.href.includes('redirect')){
        if(user.is_requestor) {
          if(user.forgot_password && user.is_independent_buyer) {
            localStorage.setItem('checkout_token', token);
            router.navigate(['/welcome']);
          } else {
            // Verifica inadimplência antes de redirecionar
            if(user.default_requestor?.is_defaulting) {
              router.navigate(['/default']);
            } else {
              router.navigate(['/contratante']);
            }
          }
        }
        else if(user.is_provider) {
          router.navigate(['/prestador']);
        }

      }
    }
  });

  return true;
}

export const canActivateProvider: CanActivateFn = (route, state) => {
  inject(AuthService).userData$.subscribe(user => {
    if (user && !user.is_provider) {
      window.location.href = '/redirect';
      return false;
    }
    return true;
  })

  return true;
}

// Rota de Onboarding
export const canActivateOnboarding: CanActivateFn = (route, state) => {
  const dialog = inject(MatDialog);
  const user = inject(AuthService).userDataSubject.value;
  if (user && user.phone) {
    dialog.open(OnboardingComponent, {
      maxWidth: '800px',
      minWidth: '400px',
      disableClose: true,
      data: {}
    });
    return false;
  }
  return true;
}

