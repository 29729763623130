import { Routes } from '@angular/router';
import { canActivateAuth,  canActivateProvider, canActivateRequestor, redirectIfAuthenticated } from './utils/auth.guard';
import { DefaultComponent } from './components/pages/default/default.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'redirect',
    pathMatch: 'full'
  },
  {
    path: 'welcome',
    loadComponent: () => import('./components/shared/layout/layout.component').then(m => m.LayoutComponent),
    children: [
      {
        path: '',
        loadComponent: () => import('./components/pages/inicio/dashboard-contratante/dashboard-contratante.component').then(m => m.DashboardContratanteComponent),
        data: { title: 'Bem-vindo à FreeLaw' }
      },

    ]
  },
  {
    path: 'ai',
    redirectTo: 'contratante/ai',
    pathMatch: 'full'
  },
  {
    path: 'redirect',
    canActivate: [redirectIfAuthenticated],
    loadComponent: () => import('./components/shared/layout/layout.component').then(m => m.LayoutComponent),
    data: { title: 'Autenticando' }
  },
  {
    path: 'contratante',
    loadComponent: () => import('./components/shared/layout/layout.component').then(m => m.LayoutComponent),
    canActivate: [canActivateAuth],
    canActivateChild: [canActivateAuth, canActivateRequestor],
    children: [
      {
        path: '',
        redirectTo: 'inicio',
        pathMatch: 'full',
      },
      {
        path: 'inicio',
        loadComponent: () => import('./components/pages/inicio/dashboard-contratante/dashboard-contratante.component').then(m => m.DashboardContratanteComponent),
        data: { title: 'Início' },
      },
      {
        path: 'favoritos',
        loadComponent: () => import('./components/pages/favoritos/favoritos.component').then(m => m.FavoritosComponent),
        data: { title: 'Favoritos' }
      },
      {
        path: 'ai',
        loadComponent: () => import('./components/pages/ai/ai.component').then(m => m.AiComponent),
        data: { title: 'Assistente IA' }
      },
      {
        path: 'servicos',
        loadComponent: () => import('./components/pages/servicos/servicos-contratante/servicos-contratante.component').then(m => m.ServicosContratanteComponent),
        data: { title: 'Serviços' }
      },
      {
        path: 'servicos/novo-envio',
        loadComponent: () => import('./components/pages/servicos/novo-envio-servico/novo-envio-servico.component').then(m => m.NovoEnvioServicoComponent),
        data: { title: 'Nova OS' }
      },
      {
        path: 'servicos/novo',
        loadComponent: () => import('./components/pages/servicos/envio-servico/envio-servico.component').then(m => m.EnvioServicoComponent),
        data: { title: 'Nova OS' }
      },
      {
        path: 'servicos/:id',
        loadComponent: () => import('./components/pages/servicos/os-contratante/os-contratante.component').then(m => m.OsContratanteComponent),
        data: { title: 'no-title' }
      },
      {
        path: 'pagamentos',
        loadComponent: () => import('./components/pages/pagamentos/pagamentos-contratante/pagamentos-contratante.component').then(m => m.PagamentosContratanteComponent),
        data: { title: 'Pagamentos' }
      },
      {
        path: 'publicacoes',
        loadComponent: () => import('./components/pages/publicacoes/publicacoes.component').then(m => m.PublicacoesComponent),
        data: { title: 'Publicações' },
        children: [
          {
            path: ':id',
            loadComponent: () => import('./components/pages/publicacoes/publicacao/publicacao.component').then(m => m.PublicacaoComponent),
            data: { title: 'Publicações' }
          }
        ]
      },
      {
        path: 'minha-conta',
        loadComponent: () => import('./components/pages/minha-conta/minha-conta-contratante/minha-conta-contratante.component').then(m => m.MinhaContaContratanteComponent),
        data: { title: 'Minha Conta' }
      },
      {
        path: 'meu-escritorio',
        loadComponent: () => import('./components/pages/meu-escritorio/meu-escritorio.component').then(m => m.MeuEscritorioComponent),
        data: { title: 'Meu Escritório' }
      },
      {
        path: 'onboarding',
        loadComponent: () => import('./components/pages/onboarding/onboarding.component').then(m => m.OnboardingComponent),
        data: { title: 'Onboarding' }
      },
      {
        path: 'plano-pagamentos',
        loadComponent: () =>
          import('./components/pages/meu-escritorio/plano-pagamentos/plano-pagamentos.component')
          .then(m => m.PlanoPagamentosComponent),
        data: { title: 'Plano e Pagamentos' }
      },
    ]
  },
  {
    path: 'prestador',
    loadComponent: () => import('./components/shared/layout/layout.component').then(m => m.LayoutComponent),
    canActivate: [canActivateAuth],
    canActivateChild: [canActivateAuth, canActivateProvider],
    children: [
      {
        path: '',
        redirectTo: 'inicio',
        pathMatch: 'full',
      },
      {
        path: 'inicio',
        loadComponent: () => import('./components/pages/inicio/dashboard-prestador/dashboard-prestador.component').then(m => m.DashboardPrestadorComponent),
        data: { title: 'Início' }
      },
      {
        path: 'ai',
        loadComponent: () => import('./components/pages/ai/ai.component').then(m => m.AiComponent),
        data: { title: 'Assistente IA' }
      },
      {
        path: 'pagamentos',
        loadComponent: () => import('./components/pages/pagamentos/pagamentos-prestador/pagamentos-prestador.component').then(m => m.PagamentosPrestadorComponent),
        data: { title: 'Pagamentos' }
      },
      {
        path: 'servicos',
        loadComponent: () => import('./components/pages/servicos/servicos-prestador/servicos-prestador.component').then(m => m.ServicosPrestadorComponent),
        data: { title: 'Serviços' }
      },
      {
        path: 'servicos/editor',
        loadComponent: () => import('./components/pages/servicos/editor/editor.component').then(m => m.EditorComponent),
        data: { title: 'Editor' }
      },
      {
        path: 'servicos/:id',
        loadComponent: () => import('./components/pages/servicos/os-prestador/os-prestador.component').then(m => m.OsPrestadorComponent),
        data: { title: 'no-title' }
      },
      {
        path: 'minha-conta',
        loadComponent: () => import('./components/pages/minha-conta/minha-conta-prestador/minha-conta-prestador.component').then(m => m.MinhaContaPrestadorComponent),
        data: { title: 'Minha Conta' }
      },

    ]
  },
  {
    path: 'welcome',
    loadComponent: () => import('./components/pages/welcome/welcome.component').then(m => m.WelcomeComponent),
    data: { title: 'Bem-vindo à FreeLaw' }
  },
  {
    path: 'assinatura-plano',
    loadComponent: () => import('./components/pages/assinatura-plano/assinatura-plano.component').then(m => m.AssinaturaPlanoComponent),
    data: { title: 'Assinatura de Plano' }
  },
  {
    path: 'login',
    loadComponent: () => import('./components/pages/login/login.component').then(m => m.LoginComponent),
    data: { title: 'Login' }
  },
  {
    path: 'contratante/cadastro',
    loadComponent: () => import('./components/pages/cadastro/cadastro-contratante/cadastro-contratante.component').then(m => m.CadastroContratanteComponent),
    data: { title: 'Cadastro Contratante' }
  },
  {
    path: 'prestador/cadastro',
    loadComponent: () => import('./components/pages/cadastro/cadastro-prestador/cadastro-prestador.component').then(m => m.CadastroPrestadorComponent),
    data: { title: 'Cadastro de Prestador' },
  },
  {
    path: 'recuperar-senha',
    loadComponent: () => import('./components/pages/login/forgot-password/forgot-password.component')
      .then(m => m.ForgotPasswordComponent),
    data: { title: 'Recuperar Senha' }
  },
  {
    path: 'design-system',
    loadComponent: () =>
      import('./components/pages/design-system/design-system.component')
        .then(m => m.DesignSystemComponent),
    data: { title: 'Design System' }
  },
  {
    path: 'default',
    component: DefaultComponent,
    canActivate: [canActivateAuth]
  }
];
