import { Observable, catchError, map, throwError } from "rxjs";
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHandlerFn, HttpRequest, HttpClient } from "@angular/common/http";
import { inject } from "@angular/core";
import { AlertService } from "../services/alert.service";
import { AuthService } from "../services/auth.service";
import { environment } from "../../environments/environment";


export function errorInterceptor(req: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>> {
  const http = inject(HttpClient);
  const alertService = inject(AlertService);


  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {

      const ERROR_LOG_URL = environment.errorLogUrl;


      if(req.url == ERROR_LOG_URL){
        return throwError(error);
      }


      if (error.status === 401) {
        if (!req.url.includes('login')) {

          localStorage.removeItem('bm_t');
          location.reload();
          return throwError(error.error.message || error.statusText);

        }
      }


      const user = atob(localStorage.getItem('bm_u_d') || '');
      http.post(ERROR_LOG_URL, {
        error: JSON.stringify(error),
        req: JSON.stringify(req),
        user: user
      }).subscribe({


        next: (res: any) => {
          if(error.status === 500){


            alertService.showAlert({
              message: 'Ocorreu um erro interno no servidor. Caso o problema persista, entre em contato com o suporte. Código do erro: ' + res.errorId,
              type: 'error',
              confirmText: 'Entendi'
            },
            {
              width: '400px'
            });
          }

          if(error.error?.detail || error.error?.info){
            alertService.showAlert({
              message: (error.error.detail || error.error.info) + ' Código do erro: ' + res.errorId,
              type: 'error',
              confirmText: 'Entendi'
            },
            {
              width: '400px'
            });
          }else{
            alertService.showAlert({
              message: 'Ocorreu um erro não identificado em nosso servidor. Caso o problema persista, entre em contato com o suporte. Código do erro: ' + res.errorId,
              type: 'error',
              confirmText: 'Entendi'

            },
            {
              width: '400px'
            });
          }

        },
        error: (err) => {
          alertService.showAlert({
            message: 'Ocorreu um erro em nosso servidor. Caso o problema persista, entre em contato com o suporte. ',
            type: 'error',
            confirmText: 'Entendi'
          },
          {
            width: '400px'
          });
        }
      });

      return throwError(error.error.message || error.statusText);

    })
  );
}
