import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ClarityService } from './services/clarity.service';
import { LoaderComponent } from './components/shared/loader/loader.component';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    LoaderComponent
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    private titleService: Title,
    private clarityService: ClarityService
  ) {}

  ngOnInit() {
    // Tracking de página em cada navegação
    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe((event) => {
      // Tracking do Clarity
      this.clarityService.trackEvent('page_view');
      this.clarityService.setTag('page_url', event.urlAfterRedirects);
      this.clarityService.setTag('page_path', event.url);

      // Atualização do título da página (mantendo funcionalidade existente)
      let route = this.router.routerState.root;
      while (route.firstChild) route = route.firstChild;
      const title = route.snapshot.data['title'] || 'Plataforma';
      if(title == 'no-title'){
        return;
      }

      this.titleService.setTitle(`${title} - Freelaw`);
    });
  }
}
