<div class="default-access-container">
  <div class="content-wrapper">
    <div class="info-card">
      <div class="card-header">
        <div class="info-card-icon">
          <span class="material-symbols-rounded">notifications_active</span>
        </div>
        <h2>Atenção!</h2>
      </div>

      <div class="info-card-content">
        <div class="message-text">
          <p class="main-message">
            Olá, {{userName}}! Identificamos que sua conta está <strong>TEMPORARIAMENTE BLOQUEADA</strong> por questões financeiras.
          </p>
          <p class="sub-message">
            Sabemos o quanto nossa plataforma é importante para você, e queremos ajudá-lo a resolver isso o quanto antes!
          </p>
          <br>
          <p>
            Para <strong>REGULARIZAR</strong> sua situação, você pode acessar diretamente a sua fatura clicando no botão abaixo:
          </p>
          <span class="w-100 d-flex justify-content-center">
            <button class="fw-btn btn-first" (click)="goToPayments()">
              <span class="material-symbols-rounded">open_in_new</span>
              REATIVAR MINHA CONTA
            </button>
          </span>
          <br>
          <p>
            Caso tenha dúvidas ou precise de qualquer suporte, nossa equipe está à disposição para ajudá-lo!
          </p>
          <span class="w-100 d-flex justify-content-center">
            <button class="fw-btn btn-text" (click)="contactSupport()">
              <span class="material-symbols-rounded">support_agent</span>
              Falar com o Suporte
            </button>
          </span>
          <br>
        </div>

        <div class="action-section">
          <div class="message-footer">
            <p>Estamos prontos para ajudar você a voltar a aproveitar todos os benefícios da nossa plataforma. <br> <strong>Conte sempre com a gente!</strong></p>
          </div>
          <span class="w-100 d-flex justify-content-center">
            <button class="fw-btn btn-text" (click)="goToLogin()">
              <span class="material-symbols-rounded">arrow_back</span>
              Voltar para o Login
            </button>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
