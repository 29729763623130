import { provideEnvironmentNgxMask } from 'ngx-mask';
import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideClarity } from 'ngx-clarity';
import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { loaderInterceptor } from './utils/loader.interceptor';
import { errorInterceptor } from './utils/error.interceptor';
import { cacheInterceptor } from './utils/cache.interceptor';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideNativeDateAdapter } from '@angular/material/core';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideAnimationsAsync(),
    provideHttpClient(
      withInterceptors([
        loaderInterceptor,
        errorInterceptor,
        cacheInterceptor
      ])
    ),
    provideEnvironmentNgxMask(),
    provideAnimations(),
    provideNativeDateAdapter(),
  ],
};
